<template>
  <div
    class="side-nav-item__inner inline-block"
    :title="item.autoTranslate ? translate(item.label) : item.label"
    :class="{
      'side-nav-item--title': item.actionType === 'title',
      parent: isParent,
    }">
    <icon
      v-if="item.icon"
      class="nav-item__inner-icon"
      :title="item.autoTranslate ? translate(item.label) : item.label"
      :icon="item.icon"></icon>
    <span
      v-if="item.label && item.label != '' && displayMode != 'icons'"
      class="side-nav-item__text">
      {{ translateUcFirst(item.label) }}
    </span>
    <icon
      v-if="isParent && displayMode !== 'icons'"
      class="parent-deeper-icon relative "
      style="margin-inline-start:auto;"
      icon="chevron-inline-end"></icon>
    <badge
      v-if="hasBadge && safeBadgeProps && displayMode === 'full'"
      v-bind="safeBadgeProps"></badge>
    <badge
      v-if="hasBadge && safeBadgeProps && displayMode === 'icons'"
      class="side-nav-item__badge--icons"
      v-bind="safeBadgeProps"></badge>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: false,
    },
    displayMode: {
      type: String,
      default: "full",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    isParent() {
      return this.item.actionType == false && this.item.children;
    },
    baseBadgeProps() {
      let defaultBadgeProps = {
        theme: "lead",
        size: "s",
      };
      if (typeof this.item.badge === "number" || typeof this.item.badge === "string") {
        return {
          ...defaultBadgeProps,
          text: this.item.badge,
        };
      }
      if (typeof this.item.badge === "function") {
        let cbResult = this.item.badge({ item: this.item });
        if (!cbResult) {
          return false;
        }
        if (cbResult && typeof cbResult !== "object") {
          return {
            ...defaultBadgeProps,
            text: cbResult,
          };
        }

        return {
          ...defaultBadgeProps,
          ...cbResult,
        };
      }

      if (typeof this.item.badge === "object") {
        return {
          ...defaultBadgeProps,
          ...this.item.badge,
        };
      }
    },
    safeBadgeProps() {
      let res = this.baseBadgeProps;
      if (this.displayMode === "icons") {
        res.text = false;
        res.icon = false;
      }
      return res;
    },
    hasBadge() {
      return !!this.item.badge;
    },
  },
};
</script>

<style scoped lang="scss"></style>
